<template>
  <div class="password-forgotten-view">
    <passwordForgotten/>
  </div>
</template>

<script>
  // @ is an alias to /src
  import passwordForgotten from '@/components/authorization/PasswordForgotten.vue'

  export default {
    name: 'PasswordForgotten',
    components: {
      passwordForgotten
    }
  }
</script>

<style scoped>
  .password-forgotten-view {
    width: 21rem;
    height: 20rem;
    display: inline-block;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -10rem;
    margin-top: -10rem;
  }
</style>