<template>
  <div v-if="!isAuthenticated" class="text-justify">
    <md-card>
      <md-card-header class="card-divider">
        <h3><b>Passwort vergessen</b></h3>
      </md-card-header>
      <md-card-content style="padding-top: 1rem; height: 20rem;">
        <form v-if="!mailSent" novalidate @submit.prevent="validateEmail">
          <md-field :class="getValidationClass('email')">
            <label for="email">Email</label>
            <md-input type="email" name="email" id="email" autocomplete="off" v-model="form.email"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.form.email.required">Email-Adresse ist notwendig</span>
            <span class="md-error" v-else-if="!$v.form.email.email">Ungültige Email-Adresse</span>
          </md-field>
          <div v-if="sending">
            <br><br><br><br><br>
            <vue-simple-spinner></vue-simple-spinner>
          </div>
          <div v-else>
            <router-link to="/Login">
              <div class="text-center"><a>Zurück zur Anmeldung</a></div>
              <md-tooltip md-direction="bottom" style="font-size: medium;">Zurück zur Anmeldung</md-tooltip>
            </router-link>
            <br>
            <button class="button expanded" type="submit"
                    :disabled="form.email.length === 0 || recaptchaResponse.length === 0">
              <i class="fi-torso"></i>&nbsp;<i class="fi-key"></i>&nbsp;&nbsp;&nbsp;Absenden
              <md-tooltip md-direction="bottom" style="font-size: medium;">Senden Sie uns ihre Email zur Passwort-Rücksetzung</md-tooltip>
            </button>
            <br>
            <vue-recaptcha
                ref="recaptcha"
                :sitekey="siteKey"
                @verify="onVerify"
                @expired="onExpired">
            </vue-recaptcha>
          </div>
        </form>
        <div v-else>
          <br>
          <div class="text-center"><h5>Email wurde gesendet an:</h5></div>
          <br>
          <div class="text-center">
            <span><b>{{form.email}}</b></span>
          </div>
          <br><br>
          <p>Überprüfen Sie bitte auch Ihren Spam-Ordner, falls Sie keine Nachricht von uns erhalten.</p>
          <br>
          <router-link to="/Login">
            <div class="text-center"><a>Zurück zur Anmeldung</a></div>
            <md-tooltip md-direction="bottom" style="font-size: medium;">Zurück zur Anmeldung</md-tooltip>
          </router-link>
          <br>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner'
  import VueRecaptcha from 'vue-recaptcha';
  import UserService from '../../services/UserService'
  import HttpErrorHandler from '../../services/HttpErrorHandler'
  import User from "../../entities/User"

  import {validationMixin} from 'vuelidate'
  import {
    required,
    email,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'PasswordForgotten',
    mixins: [validationMixin],

    components: {
      'vue-simple-spinner': Spinner,
      VueRecaptcha,
      User,
    },
    data: () => ({
      form: {
        email: '',
      },
      sending: false,
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaResponse: '',
      mailSent: false,
    }),

    methods: {

      onVerify: function (response) {
        this.recaptchaResponse = response;
        localStorage.setItem('x-recaptcha', response);
      },

      onExpired: function () {
        this.resetCaptcha();
      },

      resetCaptcha() {
        this.recaptchaResponse = '';
        localStorage.removeItem('x-recaptcha');
      },

      getValidationClass(fieldName) {
        const field = this.$v.form[fieldName];
        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateEmail() {
        this.$v.$touch();
        if (!this.$v.$invalid && this.recaptchaResponse.length > 0) {
          let user = JSON.parse(JSON.stringify(User.user));
          user.email = this.form.email;
          this.forgotPassword(user);
        }
      },

      forgotPassword(user) {
        this.sending = true;
        UserService.forgotPassword(user)
          .then(() => {
            this.$store.commit('successMsg', 'Email wurde gesendet an:&nbsp;&nbsp;<b>' + this.form.email + '</b>');
            this.mailSent = true;
            this.resetCaptcha();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Rücksetzen des Passworts für Benutzer mit Email ' + this.form.email);
            this.sending = false;
            this.resetCaptcha();
            this.$refs.recaptcha.reset();
          });
      },
    },

    validations: {
      form: {
        email: {
          required,
          email
        },
      }
    },
    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },
      role() {
        return this.$store.getters.role
      },
      isSage() {
        return this.$store.getters.sage
      },
      isAdmin() {
        return this.$store.getters.admin
      },
    }
  }
</script>

<style lang="scss" scoped>
  input, input:focus {
    border-width: 0;
    box-shadow: none;
  }

  p {
    font-weight: normal;
  }

  h5 {
    font-weight: 300;
  }

  h5 b {
    font-weight: 400;
    color: cadetblue;
  }
</style>